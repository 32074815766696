import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Badge, Button, ButtonToolbar, Modal } from "reactstrap";
import { NotificationManager } from "react-notifications";
import moment from "moment";

export default class deleteEmployee extends Component {
  constructor(props) {
    super(props);
    this.deleteStudent = this.deleteStudent.bind(this);
    this.state = {
      modal: false,
      modalS: false,
    };
    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
  }
  toggle() {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  }
  toggle2() {
    this.setState((prevState) => ({ modalS: !prevState.modalS }));
  }

  deleteStudent() {
    axios
      .delete(
        "https://api.employeeloyalty.upoint.mn/employee/delete/" +
          this.props.todo._id
      )
      .then((res) => {
        console.log(res.data);
        NotificationManager.success(res.data.message, "", 5000);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onSubmit(e) {
    e.preventDefault();

    const obj = {
      to_prove: 2,
      to_prove_at: moment().format(),
    };

    axios
      .post(
        "https://api.employeeloyalty.upoint.mn/employee/prove/" +
          this.props.match.params.id,
        obj
      )
      .then((res) => {
        this.props.history.push("/partner/list");
        console.log(res.data);
        NotificationManager.success(res.data, "", 5000);
      });

    this.props.history.push("/partner");
  }
  render() {
    const { modal, modalS } = this.state;
    return (
      <tr>
        <td>{this.props.todo.last_name}</td>
        <td>{this.props.todo.first_name}</td>
        <td>{this.props.todo.registration_number}</td>
        <td>{this.props.todo.phone_number}</td>
        <td>{this.props.todo.department}</td>
        <td>{this.props.todo.position}</td>
        <td>{this.props.todo.home_address}</td>
        <td>{this.props.todo.segment}</td>
        <td>{this.props.todo.work_started_at}</td>
        <td>{this.props.todo.created_at}</td>
        {/* <td>
              <Link to={"/edit/" + props.todo._id}>Edit</Link>
            </td> */}
        {/* <td style={{ width: "100px" }}>
          <Badge color="warning">
            <Link
              to={"/partner/editEmployee/" + this.props.todo._id}
              style={{ color: "white" }}
            >
              Засах
            </Link>
          </Badge>
        </td> */}
        <td style={{ width: "100px" }}>
          <Badge color="danger" onClick={this.toggle2}>
            test
          </Badge>
          <Modal
            isOpen={modalS}
            toggle={this.toggle2}
            style={{
              // width: "200px",
              // height: "50px",
              // position: "absolute",
              // marginLeft: "1500px",
              marginTop: "400px",
            }}
          >
            <div className="modal__body">
              <Button color="danger" onClick={this.toggle}>
                Устгах
              </Button>
              <Button color="warning">
                <Link
                  to={"/partner/editEmployee/" + this.props.todo._id}
                  style={{ color: "white" }}
                >
                  Засах
                </Link>
              </Button>
              <Button color="primary" onClick={this.onSubmit}>
                Баталгаажуулах хүсэлт илгээх
              </Button>
            </div>
          </Modal>
          <Modal isOpen={modal} toggle={this.toggle}>
            <div className="modal__header"></div>
            <div className="modal__body">
              {" "}
              Ажилтан {this.props.todo.first_name} -г устгах уу?
            </div>
            <ButtonToolbar className="modal__footer">
              <Button className="modal_ok" onClick={this.deleteStudent}>
                Тийм
              </Button>{" "}
              <Button className="modal_cancel" onClick={this.toggle}>
                Үгүй
              </Button>{" "}
            </ButtonToolbar>
          </Modal>
        </td>
      </tr>
    );
  }
}
