import React, { Component } from "react";
import { withRouter } from "react-router-dom";
class SegmentSelect extends Component {
  render() {
    return (
      <option value={this.props.departments.department}>
        {this.props.departments.department}
      </option>
    );
  }
}
export default withRouter(SegmentSelect);
