import React from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "../Layout/index";
import LayoutPartner from "../LayoutPartner/index";
import LayoutProver from "../LayoutProver/index";
import MainWrapper from "./MainWrapper";

import LogIn from "../LogIn/index";
import BurtgelErh from "../Example/index";
import Segment from "../Segment/index";
import Medeelel from "../Medeelel/index";
import Department from "../Company/index";
import Zasah from "../Example/components/Zasah";
import Burtgeh from "../Example/components/Burtgeh";
import Partner from "../Partner/index";
import AddEmployee from "../Partner/components/addEmployee";
import EditEmployee from "../Partner/components/editEmployee";
import ApplyEdit from "../Partner/components/apply_edit";
import SignedEmployee from "../Partner/components/verified";
import forgotPassword from "../LogIn/components/forgotForm";
import Prover from "../Prover/index";
import Verified from "../Prover/components/verified";
import changeForm from "../LogIn/components/changeForm";
import createSegment from "../Segment/components/createSegment";
import addNews from "../Medeelel/components/addNews";
import addDepartment from "../Company/components/addDepartment";
import NewsPartner from "../Partner/components/news"
import NewsDirector from "../Prover/components/news"
import fullInfo from "../Prover/components/fullInfo"

const PartnerRoute = () => (
  <Switch>
    <Route path="/partner/list" component={Partner} />
    <Route path="/partner/addEmployee" component={AddEmployee} />
    <Route path="/partner/editEmployee/:id" component={EditEmployee} />
    <Route path="/partner/apply_edit/:id" component={ApplyEdit} />
    <Route path="/partner/signed_employee/" component={SignedEmployee} />
    <Route path="/partner/news/" component={NewsPartner} />
  </Switch>
);
const Pages = () => (
  <Switch>
    <Route path="/pages/burtgelerh" component={BurtgelErh} />
    <Route path="/pages/segment" render={() => <Segment />} />
    <Route path="/pages/medeelel" render={() => <Medeelel />} />
    <Route path="/pages/department" render={() => <Department />} />
    <Route path="/pages/adminBurtgel/edit/:id" component={Zasah} />
    <Route path="/pages/adminBurtgel/add" component={Burtgeh} />
    <Route path="/pages/addSegment" component={createSegment} />
    <Route path="/pages/addNews" component={addNews} />
    <Route path="/pages/addDepartment" component={addDepartment} />
  </Switch>
);
const ProverRoute = () => (
  <Switch>
    <Route path="/application/list" render={() => <Prover />} />
    <Route path="/application/verified" render={() => <Verified />} />
    <Route path="/application/news" render={() => <NewsDirector />} />
    <Route path="/application/fullInfo/:id" component={fullInfo} />
  </Switch>
);

const partnerRoutes = () => (
  <div>
    <LayoutPartner />
    <div className="container__wrap">
      <Route path="/partner" component={PartnerRoute} />
    </div>
  </div>
);
const proverRoutes = () => (
  <div>
    <LayoutProver />
    <div className="container__wrap">
      <Route path="/application" component={ProverRoute} />
    </div>
  </div>
);
const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/pages" render={() => <Pages />} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/change_password" component={changeForm} />
        <Route exact path="/log_in" component={LogIn} />
        <Route path="/partner" component={partnerRoutes} />
        <Route path="/pages" component={wrappedRoutes} />
        <Route path="/application" component={proverRoutes} />

      </Switch>
    </main>
  </MainWrapper>
);

const LoginRouter = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route path="/login" component={LogIn} />
        <Route exact path="/forgot_password" component={forgotPassword} />
        <Route path="/" component={LogIn} />
      </Switch>
    </main>
  </MainWrapper>
);
const forgotRouter = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/forgot_password" component={forgotPassword} />
      </Switch>
    </main>
  </MainWrapper>
);

export default {
  Router,
  LoginRouter,
  forgotRouter
};
