import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import Topbar from "./topbar/TopbarPartner";

class LayoutProver extends Component {
  render() {
    const { sidebar } = this.props;

    const layoutClass = classNames({
      layout: true,
      "layout--collapse": sidebar.collapse,
    });

    return (
      <div className={layoutClass}>
        <Topbar />
      </div>
    );
  }
}

export default withRouter(
  connect((state) => ({
    sidebar: state.sidebar,
  }))(LayoutProver)
);
