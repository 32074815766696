import React, { PureComponent } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { reduxForm } from "redux-form";
import "../../scss/component/navbar.scss";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import CardTitle from "reactstrap/lib/CardTitle";
import ExampleCard from "./components/ExampleCard";
import axios from "axios";

// import Table from "./components/table";
// import SegmentSelect from "./components/segmentSelect";

class News extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      contents: [],
    };

    // this.onDelete = this.onDelete.bind(this);
  }
  componentDidMount() {
    axios
      .get("https://api.employeeloyalty.upoint.mn/news/")
      .then((response) => {
        console.log(response.data, "response");
        this.setState({ contents: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render() {
    const { contents } = this.state;
    const { handleSubmit } = this.props;
    return (
      <div className="content">
        <Container style={{ marginLeft: "0px" }}>
          <Row>
            <Col md={1.5}>
              <button className="btn">
                <Link to={"/pages/addNews"} style={{ color: "white" }}>
                  + Мэдээ, мэдээлэл үүсгэх
                </Link>
              </button>
            </Col>

            <NotificationContainer />
          </Row>
          <Row>
            <Card>
              <CardBody style={{ height: "auto", width: "1844px" }}>
                <Col>
                  <CardTitle className="card__title">Мэдээ, мэдээлэл</CardTitle>
                  <hr />
                  {this.state.contents.map((contents) => (
                    <ExampleCard
                      key={this.state.contents.id}
                      contents={contents}
                    />
                  ))}
                </Col>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    );
  }
}

export default reduxForm({
  form: "vertical_form", // a unique identifier for this form
})(withTranslation("common")(News));
