import React, { PureComponent } from "react";
import {
  Card,
  CardBody,
  Col,
  CardImg,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
  Row,
} from "reactstrap";
import axios from "axios";

class ExampleCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      contents: [],
    };

    // this.onDelete = this.onDelete.bind(this);
  }
  componentDidMount() {
    axios
      .get("https://api.employeeloyalty.upoint.mn/news/")
      .then((response) => {
        console.log(response.data, "response");
        this.setState({ contents: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log(this.props, "props");
  }
  render() {
    return (
      <Col md={6}>
        <Row>
          <Col>
            <Card>
              <Row>
                <Col sm={5}>
                  <CardImg
                    src={this.props.contents.picture}
                    alt="Card image cap"
                    className="catalog-item__img"
                  />
                </Col>

                <Col sm={7}>
                  <CardBody>
                    <CardTitle className="catalog-item__title">
                      {this.props.contents.title}
                    </CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted">
                      {this.props.contents.short_desc}
                    </CardSubtitle>
                    <CardText>{this.props.contents.desc}</CardText>
                    <Button>Дэлгэрэнгүй</Button>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
    );
  }
}

export default ExampleCard;
