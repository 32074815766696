import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import logo from "../../../shared/img/logo/login-logo.png";
import axios from "axios";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";

class forgotForm extends PureComponent {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);

    this.state = {
      email: "",
    };
  }

  onChangeUsername(e) {
    this.setState({
      email: e.target.value,
    });
  }
  onSubmit(e) {
    e.preventDefault();

    const registrationData = {
      client_id: "9n8823WHwxvf1I",

      email: this.state.email,
    };

    axios
      .post("https://auth.upoint.mn/internal/recover", registrationData)
      .then((res) => {
        this.setState({
          email: this.state.email,
        });
        if (!res.data.error) {
          NotificationManager.success(res.data.message, "", 5000);
        } else {
          NotificationManager.error(res.data.error_description, "", 5000);
        }
      });
  }
  render() {
    const { handleSubmit } = this.props;

    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div
              style={{
                textAlign: "center",
                display: "block",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "100px",
                }}
              ></img>
            </div>
            <NotificationContainer style={{ position: "absolute" }} />

            <form className="form" onSubmit={handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">И-мэйл хаяг</span>
                <div className="form__form-group-field">
                  <Field
                    name="email"
                    component="input"
                    type="text"
                    placeholder="И-мэйл хаяг"
                    value={this.state.email}
                    onChange={this.onChangeUsername}
                  />
                </div>
              </div>

              <Button
                onClick={this.onSubmit}
                className="btn account__btn account__btn--small"
              >
                Шинэ нууц үг авах
              </Button>
              <Link className="btn account__btn account__btn--small" to={"/"}>
                Буцах
              </Link>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: "log_in_form",
})(forgotForm);
