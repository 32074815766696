import React, { PureComponent } from "react";
import { Card, Col, Row, Table, Button } from "reactstrap";
import axios from "axios";
import Verify from "../components/verifyTab";

class Tab2 extends PureComponent {
  constructor(props) {
    super(props);
    this.harii = this.harii.bind(this);
  }

  todoList() {
    return this.props.ceos.map(function (currentTodo, i) {
      return <Verify todo={currentTodo} key={i} />;
    });
  }
  harii() {
    console.log(this.props.ceos, "orj irsen todos");
    console.log(this.props.company_id, "orj irsen company_id");
  }
  render() {
    return (
      <Card>
        <Row>
          <Col>
            <Card body style={{ height: "auto", width: "auto" }}>
              <div style={{ marginLeft: "3%" }}>
                <Table
                  className="table--bordered table--head-accent"
                  responsive
                  hover
                >
                  <thead>
                    <tr>
                      <th style={{ width: "300px" }}>Баталгаажуулах эрх</th>
                      <th style={{ width: "300px" }}>Имэйл хаяг</th>

                      <th style={{ width: "300px" }}>Эрх үүсгэсэн огноо</th>
                      <th style={{ width: "100px" }}></th>
                      <th style={{ width: "100px" }}></th>
                    </tr>
                  </thead>
                  <tbody>{this.todoList()}</tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Card>
    );
  }
}

export default Tab2;
