import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import logo from "../../../shared/img/logo/login-logo.png";
import EyeIcon from "mdi-react/EyeIcon";

import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";

class changeForm extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancelChange = this.cancelChange.bind(this);
    this.onChangeDefaultPassword = this.onChangeDefaultPassword.bind(this);
    this.showPassword = this.showPassword.bind(this);
    this.onChangeNewPassword = this.onChangeNewPassword.bind(this);
    this.onChangeNewPasswordConfirm =
      this.onChangeNewPasswordConfirm.bind(this);

    this.state = {
      default_password: "",
      new_password: "",
      new_password_confirm: "",
      showPassword: false,
      errors: {},
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  onChangeDefaultPassword(e) {
    this.setState({
      default_password: e.target.value,
    });
  }
  onChangeNewPassword(e) {
    this.setState({
      new_password: e.target.value,
    });
  }
  onChangeNewPasswordConfirm(e) {
    this.setState({
      new_password_confirm: e.target.value,
    });
  }
  validate() {
    let new_password_confirm = this.state.new_password_confirm;
    let new_password = this.state.new_password;
    let errors = {};
    let isValid = true;

    if ((new_password = !new_password_confirm)) {
      isValid = false;

      errors["error_password_match"] = "Нууц үг зөрж байна!";
    }

    this.setState({
      errors: errors,
    });
    console.log(this.state.errors, "errors");
    return isValid;
  }
  cancelChange() {
    this.props.history.push(localStorage.getItem("view"));
  }
  handleSubmit(e) {
    e.preventDefault();
    console.log(this.state.default_password, "default");
    console.log(this.state.new_password, "new pasawr");
    console.log(this.state.new_password_confirm, "confirm");

    const credentials = {
      old_password: this.state.default_password,
      new_password: this.state.new_password,
      client_id: "9n8823WHwxvf1I",
    };

    if (this.state.new_password === this.state.new_password_confirm) {
      axios
        .post("https://auth.upoint.mn/internal/user", credentials)
        .then((res) => {
          this.setState({
            default_password: this.state.default_password,
            new_password: this.state.password,
          });
          if (!res.data.error) {
            this.props.history.push(localStorage.getItem("view"));
            NotificationManager.success("Амжилттай!", "", 5000);
          } else {
            NotificationManager.error(res.data.error_description, "", 5000);
          }
        });
    } else {
      NotificationManager.error("Нууц үг зөрж байна!", "", 5000);
    }
  }

  render() {
    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div
              style={{
                textAlign: "center",
                display: "block",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "100px",
                }}
              ></img>
            </div>
            <NotificationContainer style={{ position: "absolute" }} />
            <form className="form" onSubmit={this.handleSubmit}>
              <NotificationContainer style={{ position: "absolute" }} />
              <div className="form__form-group">
                <span className="form__form-group-label">Одоогийн нууц үг</span>
                <div className="form__form-group-field">
                  <Field
                    name="default_password"
                    component="input"
                    type="password"
                    placeholder="Одоогийн нууц үг"
                    value={this.state.default_password}
                    onChange={this.onChangeDefaultPassword}
                  />
                  
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Шинэ нууц үг</span>
                <div className="form__form-group-field">
                  <Field
                    name="new_password"
                    component="input"
                    type="password"
                    placeholder="Шинэ нууц үг"
                    value={this.state.new_password}
                    onChange={this.onChangeNewPassword}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Шинэ нууц үг давтах
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="new_password_confirm"
                    component="input"
                    type="password"
                    placeholder="Шинэ нууц үг давтах"
                    value={this.state.new_password_confirm}
                    onChange={this.onChangeNewPasswordConfirm}
                  />
                </div>
                {this.state.errors.error_password_match}
              </div>

              <button
                className="btn account__btn account__btn--small"
                type="submit"
                value="Нэвтрэх"
              >
                Солих
              </button>
              <button
                className="btn btn-outline-primary account__btn account__btn--small"
                onClick={this.cancelChange}
              >
                Болих
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: "log_in_form",
})(withRouter(changeForm));
