import React, { Component, Fragment } from "react";
import { hot } from "react-hot-loader";
import { Provider } from "react-redux";
import { BrowserRouter, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "../../scss/app.scss";
import Router from "./Router";
import store from "./store";
import axios from "axios";
import ScrollToTop from "./ScrollToTop";
import jwt_decode from "jwt-decode";
import { authService } from "../../services/auth.service";

// axios.interceptors.request.use(
//   (config) => {
//     console.log(config.url, "url");
//     const { origin } = new URL(config.url);
//     const allowedOrigins = [
//       "http://https://api.employeeloyalty.upoint.mn",
//       "https://auth.upoint.mn",
//     ];
//     const token = localStorage.getItem("access_token");
//     console.log(token, "token");
//     // if (config.url !== "https://auth.upoint.mn/internal/login") {
//     //   try {
//     //     var decoded = jwt_decode(token);
//     //     if (Date.now() <= decoded.exp * 1000) {
//     //       localStorage.clear();
//     //       // this.props.history.push("/");
//     //       // throw new Error("Token expired");
//     //       return <Redirect to="/" />;
//     //     }
//     //   } catch (err) {
//     //     console.log(err, "errpr");
//     //     return <Redirect to="/" />;
//     //   }
//     // }

//     if (allowedOrigins.includes(origin)) {
//       config.headers.authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
axios.interceptors.request.use(
  (config) => {
    const { origin } = new URL(config.url);
    const allowedOrigins = [
      "https://api.employeeloyalty.upoint.mn",
      "https://auth.upoint.mn",
      "http://localhost:4000",
      "http://192.186.10.38:3000",
    ];

    const token = authService.accessToken;
    if (config.url !== "https://auth.upoint.mn/internal/login") {
      try {
        var decoded = jwt_decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          authService.logout();
        }
      } catch (err) {
        console.log(err, "error");
      }
    }
    if (allowedOrigins.includes(origin)) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log(error, "error");
    return Promise.reject(error);
  }
);

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
      access_token: localStorage.getItem("access_token"),
    };
  }

  componentDidMount() {
    window.addEventListener("load", () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });

    this.subscription = authService.subscriber.subscribe(
      (updatedAccessToken) => {
        console.log("Updating token :", updatedAccessToken);
        this.setState({ access_token: updatedAccessToken });
      }
    );
  }

  render() {
    const { loaded, loading } = this.state;
    if (this.state.access_token) {
      return (
        <Provider store={store}>
          <BrowserRouter>
            <ScrollToTop>
              <Fragment>
                {!loaded && (
                  <div className={`load${loading ? "" : " loaded"}`}>
                    <div className="load__icon-wrap">
                      <svg className="load__icon">
                        <path
                          fill="#4ce1b6"
                          d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                        />
                      </svg>
                    </div>
                  </div>
                )}
                <div>
                  <Router.Router />
                </div>
              </Fragment>
            </ScrollToTop>
          </BrowserRouter>
        </Provider>
      );
    } else {
      return (
        <Provider store={store}>
          <BrowserRouter>
            <ScrollToTop>
              <Fragment>
                {!loaded && (
                  <div className={`load${loading ? "" : " loaded"}`}>
                    <div className="load__icon-wrap">
                      <svg className="load__icon">
                        <path
                          fill="#4ce1b6"
                          d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                        />
                      </svg>
                    </div>
                  </div>
                )}
                <div>
                  <Router.LoginRouter />
                </div>
              </Fragment>
            </ScrollToTop>
          </BrowserRouter>
        </Provider>
      );
    }
  }
}

export default hot(module)(App);
