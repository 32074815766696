import React, { PureComponent } from "react";
import { Col, Row, Modal, ButtonToolbar, Button, Container } from "reactstrap";
import { withRouter } from "react-router-dom";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import Tooltip from "@material-ui/core/Tooltip";
import {
  DataGrid,
  GridToolbar,
  GridExportCsvOptions,
  GridToolbarExport,
  GridToolbarContainer,
} from "@material-ui/data-grid";

import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import Badge from "reactstrap/lib/Badge";
import moment from "moment";

const columns = [
  {
    field: "field_status",
    headerName: "Төлөв",
    width: 100,
    color: "blue",
    // type: "boolean",
    renderCell: (params) => {
      if (params.row.field_status == "Шинэ") {
        return (
          <Badge style={{ backgroundColor: "green" }}>
            {params.row.field_status}
          </Badge>
        );
      } else if (params.row.field_status == "Буцаагдсан") {
        return (
          <Badge style={{ backgroundColor: "orange" }}>
            {params.row.field_status}
          </Badge>
        );
      } else if (params.row.field_status == "Хүлээгдэж буй") {
        return (
          <Badge style={{ backgroundColor: "blue" }}>
            {params.row.field_status}
          </Badge>
        );
      } else if (params.row.field_status == "Устгах") {
        return (
          <Badge style={{ backgroundColor: "red" }}>
            {params.row.field_status}
          </Badge>
        );
      } else if (params.row.field_status == "Засах") {
        return <Badge style={{ backgroundColor: "blue" }}>Хүлээгдэж буй</Badge>;
      }
    },
  },

  {
    field: "last_name",
    headerName: "Овог",
    width: 150,
    color: "blue",
    // renderCell: renderCellExpand
  },
  { field: "first_name", headerName: "Нэр", width: 170 },
  { field: "registration_number", headerName: "Регистрийн дугаар", width: 150 },
  { field: "email", headerName: "Имэйл", width: 190 },
  { field: "phone_number", headerName: "Утасны дугаар", width: 120 },
  {
    field: "department",
    headerName: "Газар хэлтэс",
    width: 260,
  },
  { field: "position", headerName: "Албан тушаал", width: 180 },

  {
    field: "work_started_at",
    headerName: "Ажилд орсон огноо",
    width: 120,
    valueGetter: (params) => {
      let result = params.row.work_started_at;
      let wsa = moment(result).format("L");
      return wsa;
    },
  },

  {
    field: "employee_status",
    headerName: "Статус",
    width: 150,
    color: "blue",
    renderCell: (params) => {
      if (params.row.employee_status == "Ажиллаж байгаа") {
        return (
          <Badge style={{ backgroundColor: "green" }}>
            {params.row.employee_status}
          </Badge>
        );
      } else if (params.row.employee_status == "Гэрээт") {
        return (
          <Badge style={{ backgroundColor: "yellow" }}>
            {params.row.employee_status}
          </Badge>
        );
      } else if (params.row.employee_status == "Хүүхэд асрах чөлөөтэй") {
        return (
          <Badge style={{ backgroundColor: "blue" }}>
            {params.row.employee_status}
          </Badge>
        );
      } else if (params.row.employee_status == "Чөлөөтэй") {
        return (
          <Badge style={{ backgroundColor: "pink" }}>
            {params.row.employee_status}
          </Badge>
        );
      }
    },
  },
];
var rowsToKeep = [];
class table extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      employees: [],
      popoverOpen: false,
      anchorEl: null,
      open: false,
      id: undefined,
    };
    this.toggle = this.toggle.bind(this);
    this.handleCellOver = this.handleCellOver.bind(this);
    this.deleteStudent = this.deleteStudent.bind(this);
  }

  deleteStudent() {
    axios
      .delete("https://api.employeeloyalty.upoint.mn/employee/delete/", {
        params: { rowsToKeep },
      })
      .then((res) => {
        console.log(res.data, "res.data");
        window.location.reload();
        NotificationManager.success(res.data.message, "", 5000);
      })
      .catch((error) => {
        NotificationManager.error(error.message, "", 5000);
        console.log(error);
      });
    this.toggle();

    console.log(rowsToKeep, "rowstoKeep");
  }
  toggle() {
    this.setState((prevState) => ({ modal: !prevState.modal }));
    console.log(this.props, "props");
  }
  onSubmit(e) {
    console.log(rowsToKeep, "rowstoKeep");
    if (rowsToKeep.length > 0) {
      e.preventDefault();

      axios
        .post(
          "https://api.employeeloyalty.upoint.mn/employee/apply",
          rowsToKeep
        )
        .then((res) => {
          // this.props.history.push("/partner/list");
          NotificationManager.success(res.data.message, "", 5000);
          window.location.reload();
        });
    } else {
      NotificationManager.error("Баталгаажуулах мөрийг сонгоно уу!");
    }

    // this.props.history.push("/partner");
  }

  handleRowSelection = (e) => {
    rowsToKeep = e;
    console.log("selected ids: " + rowsToKeep);
  };

  handleCellOver = (e) => {
    return (
      <Tooltip title={e.value} placement="top-start">
        {e.value}
      </Tooltip>
    );
  };

  render() {
    const { modal } = this.state;
    const { popoverOpen } = this.state;
    function CustomToolbar() {
      return (
        <GridToolbarContainer>
          <GridToolbarExport
            csvOptions={{
              utf8WithBom: true,
              allColumns: true,
              fileName: "hahaha",
            }}
          />
        </GridToolbarContainer>
      );
    }
    return (
      <Container>
        <Row>
          <Col md={8.5}></Col>
          <Col md={1.5}>
            <Button
              style={{
                color: "white",
                backgroundColor: "#FC7959",
                width: "180px",
                margin: "0 10px 5px 5px",
              }}
              className="btn"
              onClick={this.toggle}
            >
              Жинхлээгүй
            </Button>
          </Col>

          <Col md={2.5}>
            <Button
              style={{
                color: "white",
                backgroundColor: "#289E57",
                width: "280px",
              }}
              className="btn"
              onClick={this.onSubmit}
            >
              Баталгаажуулах хүсэлт илгээх
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ height: "560px", width: "100%" }}>
              <div className="material-table__toolbar-wrap">
                <Modal isOpen={modal} toggle={this.toggle}>
                  <div className="modal__header"></div>
                  <div className="modal__body"> Ажилтан-г устгах уу?</div>
                  <ButtonToolbar className="modal__footer">
                    <Button className="modal_ok" onClick={this.deleteStudent}>
                      Тийм
                    </Button>{" "}
                    <Button className="modal_cancel" onClick={this.toggle}>
                      Үгүй
                    </Button>{" "}
                  </ButtonToolbar>
                </Modal>
              </div>
              <NotificationContainer style={{ position: "absolute" }} />
              <ReactTooltip place="left" type="success" effect="solid" />
              <DataGrid
                getRowId={(row) => row._id}
                rows={this.props.employees}
                columns={columns}
                checkboxSelection
                rowsPerPageOptions={[30, 50, 100]}
                onSelectionModelChange={this.handleRowSelection}
                onCellOver={this.handleCellOver}
                onRowDoubleClick={(e) =>
                  this.props.history.push("/partner/editEmployee/" + e.id)
                }
                disableColumnMenu
                localeText={{
                  toolbarDensity: "Size",
                  toolbarDensityLabel: "Size",
                  toolbarDensityCompact: "Small",
                  toolbarDensityStandard: "Medium",
                  toolbarDensityComfortable: "Large",
                  filterOperatorIs: "is",
                  filterValueAny: "any",
                  filterValueTrue: "true",
                  filterValueFalse: "false",
                }}
                components={{
                  Toolbar: GridToolbar,
                }}
                GridExportCsvOptions={{
                  utf8WithBom: true,
                }}
              />
              <NotificationContainer style={{ position: "absolute" }} />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(table);
