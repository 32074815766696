import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Collapse, Navbar, Nav, NavItem, NavLink } from "reactstrap";
import {authService} from '../../../services/auth.service'
class TopbarProver extends PureComponent {
  logOut() {
    localStorage.clear()
  }
  render() {
    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <Link className="topbar__logo" to="/partner/list" />
          </div>
          <div className="navbar">
            <Navbar light expand="md" variant="light">
              <Collapse navbar>
                <Nav navbar>
                  <NavItem>
                    <NavLink>
                      <Link to={"/application/news"} style={{ color: "white" }}>
                        МЭДЭЭ, МЭДЭЭЛЭЛ
                      </Link>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink>
                      <Link
                        to={"/application/list"}
                        style={{ color: "white", marginLeft: "50px" }}
                      >
                        БАТАЛГААЖУУЛАХ
                      </Link>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink>
                      <Link
                        to={"/application/verified"}
                        style={{ color: "white", marginLeft: "50px" }}
                      >
                        БҮРТГЭЛТЭЙ АЖИЛТАН
                      </Link>
                    </NavLink>
                  </NavItem>
                  <div className="topbar__right-partner">
                    <NavItem>
                      <NavLink
                        href="/change_password"
                        style={{ color: "white" }}
                      >
                        НУУЦ ҮГ СОЛИХ
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                      onClick={authService.logout}
                        href="/"
                        style={{
                          backgroundColor: "#40B0EF",
                          color: "white",
                          border: "none",
                          borderRadius: "6px",
                          marginLeft: "50px",
                          width: "70px",
                        }}
                      >
                        ГАРАХ
                      </NavLink>
                    </NavItem>
                  </div>
                </Nav>
              </Collapse>
            </Navbar>
          </div>
        </div>
      </div>
    );
  }
}

export default TopbarProver;
