import React, { PureComponent } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ButtonToolbar,
  Badge,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Todos from "../../Partner/components/deleteEmployee";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { Button } from "antd";
import "antd/dist/antd.css";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";

const columns = [
  // {
  //   field: "field_status",
  //   headerName: "Төлөв",
  //   width: 100,
  //   color: "blue",
  //   renderCell: (params) => {
  //     if (params.row.field_status == "Шинэ") {
  //       return (
  //         <Badge style={{ backgroundColor: "green" }}>
  //           {params.row.field_status}
  //         </Badge>
  //       );
  //     } else if (params.row.field_status == "Баталгаажсан") {
  //       return (
  //         <Badge style={{ backgroundColor: "green" }}>
  //           {params.row.field_status}
  //         </Badge>
  //       );
  //     } else if (params.row.field_status == "Хүлээгдэж буй") {
  //       return (
  //         <Badge style={{ backgroundColor: "blue" }}>
  //           {params.row.field_status}
  //         </Badge>
  //       );
  //     }
  //   },
  // },

  {
    field: "last_name",
    headerName: "Овог",
    width: 150,
    color: "blue",
    // renderCell: renderCellExpand
  },
  { field: "first_name", headerName: "Нэр", width: 170 },
  { field: "registration_number", headerName: "Регистрийн дугаар", width: 150 },
  { field: "email", headerName: "Имэйл", width: 190 },
  { field: "phone_number", headerName: "Утасны дугаар", width: 110 },
  {
    field: "department",
    headerName: "Газар хэлтэс",
    width: 300,
  },
  { field: "position", headerName: "Албан тушаал", width: 180 },

  // {
  //   field: "work_started_at",
  //   headerName: "Ажилд орсон огноо",
  //   width: 120,
  //   valueGetter: (params) => {
  //     let result = params.row.work_started_at;
  //     let wsa = moment(result).format("L");
  //     return wsa;
  //   },
  // },
  {
    field: "work_started_at",
    headerName: "Ажилд орсон огноо",
    width: 120,
    type: "date",
    valueGetter: (params) => {
      let result = params.row.work_started_at;
      let wsa = moment(result).format("L");
      return wsa;
    },
  },
  {
    field: "employee_status",
    headerName: "Ажилты статус",
    width: 200,
    color: "blue",
    renderCell: (params) => {
      if (params.row.employee_status == "Ажиллаж байгаа") {
        return (
          <Badge style={{ backgroundColor: "green" }}>
            {params.row.employee_status}
          </Badge>
        );
      } else if (params.row.employee_status == "Гэрээт") {
        return (
          <Badge style={{ backgroundColor: "yellow" }}>
            {params.row.employee_status}
          </Badge>
        );
      } else if (params.row.employee_status == "Хүүхэд асрах чөлөөтэй") {
        return (
          <Badge style={{ backgroundColor: "blue" }}>
            {params.row.employee_status}
          </Badge>
        );
      } else if (params.row.employee_status == "Чөлөөтэй") {
        return (
          <Badge style={{ backgroundColor: "pink" }}>
            {params.row.employee_status}
          </Badge>
        );
      }
    },
  },
  // {
  //   field: "emp_status_change_date",
  //   headerName: "Статус солигдсон огноо",
  //   width: 120,
  //   valueGetter: (params) => {
  //     let result = params.row.emp_status_change_date;
  //     let wsa = moment(result).format("L");

  //     return wsa;
  //   },
  // },
];
var rowsToKeep = [];

class Verified extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      todos: [],
      rn: "",
      phone: "",
      name: "",
      modal: false,
      employee_status: "",
    };
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeRN = this.onChangeRN.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onChangeEmpStatus = this.onChangeEmpStatus.bind(this);
    this.deleteStudent = this.deleteStudent.bind(this);
  }

  componentDidMount() {
    axios
      .get("https://api.employeeloyalty.upoint.mn/employee/verified_ceo", {
        params: {
          name: this.state.name,
          phone: this.state.phone,
          rn: this.state.rn,
          employee_status: this.state.employee_status,
        },
      })
      .then((response) => {
        let filteredEmpListbyPositionName = [];
        for (let i = 0; i < response.data.length; i++) {
          if (
            response.data[i].position !== "Хамтын ажиллагааны газрын захирал" &&
            response.data[i].position !== "Гүйцэтгэх захирал" &&
            response.data[i].position !==
              "Мэдээллийн технологийн газрын менежер" &&
            response.data[i].position !==
              "Бизнес хөгжил, төлөвлөлтийн газрын менежер" &&
            response.data[i].position !== "Маркетингийн хэлтсийн менежер" &&
            response.data[i].position !== "Санхүүгийн газрын захирал" &&
            response.data[i].position !==
              "Захиргаа,хүний нөөцийн хэлтсийн менежер" &&
            response.data[i].position !== "ТУЗ-ийн дарга" &&
            response.data[i].position !== "ТУЗ-ийн гишүүн"
          ) {
            filteredEmpListbyPositionName.push(response.data[i]);
          } else {
            // delete response.data[i].last_name;
            // delete response.data[i].registration_number;
            filteredEmpListbyPositionName.push(response.data[i]);
          }
        }
        this.setState({ todos: filteredEmpListbyPositionName });
        console.log(
          filteredEmpListbyPositionName,
          "filtered employee list by position"
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  onSearch() {
    axios
      .get("https://api.employeeloyalty.upoint.mn/employee/verified_ceo", {
        params: {
          name: this.state.name,
          phone: this.state.phone,
          rn: this.state.rn,
          employee_status: this.state.employee_status,
        },
      })
      .then((response) => {
        this.setState({ todos: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  toggle() {
    this.setState((prevState) => ({ modal: !prevState.modal }));
    console.log(this.props, "props");
  }

  reject(e) {
    e.preventDefault();

    axios
      .post(
        "https://api.employeeloyalty.upoint.mn/employee/reject/" + rowsToKeep
      )
      .then((res) => {
        console.log(res.data);
        NotificationManager.success(res.data.message, "", 5000);
      });
  }
  onChangeName(e) {
    this.setState({ name: e.target.value });
    console.log(this.state.employees, "eniig harddss");
  }
  onChangeRN(e) {
    this.setState({ rn: e.target.value });
  }
  onChangePhone(e) {
    this.setState({ phone: e.target.value });
  }
  todoList() {
    return this.state.todos.map(function (currentTodo, i) {
      return <Todos todo={currentTodo} key={i} />;
    });
  }
  handleRowSelection = (e) => {
    rowsToKeep = e;
    console.log("selected ids: " + rowsToKeep);
  };
  onChangeEmpStatus(e) {
    this.setState({ employee_status: e.target.value });
  }
  deleteStudent() {
    axios
      .post(
        "https://api.employeeloyalty.upoint.mn/employee/apply_delete/",
        rowsToKeep
      )
      .then((res) => {
        console.log(res.data, "res.data");
        NotificationManager.success(res.data.message, "", 5000);
        window.location.reload();
      })
      .catch((error) => {
        NotificationManager.error(error.message, "", 5000);
        console.log(error);
      });
    this.toggle();
    // window.location.reload(false);
  }

  render() {
    const { modal } = this.state;
    return (
      <div className="content">
        <Container>
          <NotificationContainer />
          <Row>
            <Card>
              <CardBody style={{ height: "auto", width: "100%" }}>
                <Row style={{ marginLeft: "10px" }}>
                  {/* <form className="form" onSubmit={handleSubmit}> */}
                  <div className="form">
                    <Col>
                      <div className="form__form-group">
                        {/* <span className="form__form-group-label">Нэр</span> */}
                        <div className="form__form-group-field">
                          <input
                            name="name"
                            component="input"
                            type="text"
                            placeholder="Нэр"
                            value={this.state.name}
                            onChange={this.onChangeName}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="form__form-group">
                        {/* <span className="form__form-group-label">
                          Регистрийн дугаар
                        </span> */}
                        <div className="form__form-group-field">
                          <input
                            name="registration_number"
                            component="input"
                            type="text"
                            placeholder="Регистрийн дугаар"
                            value={this.state.rn}
                            onChange={this.onChangeRN}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="form__form-group">
                        {/* <span className="form__form-group-label">
                          Утасны дугаар{" "}
                        </span> */}
                        <div className="form__form-group-field">
                          <input
                            name="phone_number"
                            component="input"
                            type="text"
                            placeholder="Утасны дугаар"
                            value={this.state.phone}
                            onChange={this.onChangePhone}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="form__form-group">
                        <div className="form__form-group-field">
                          <select
                            value={this.state.employee_status}
                            onChange={this.onChangeEmpStatus}
                            placeholder="Ажилтны статус"
                            // className="select-index"
                            style={{
                              height: "32px",
                              borderColor: "#f4f7f8",
                              boxShadow: "none",
                              color: "gray",
                            }}
                          >
                            <option value="" selected>
                              Ажилтны статус
                            </option>
                            <option value="1">Ажиллаж байгаа</option>
                            <option value="2">Хүүхэд асрах чөлөөтэй</option>
                            <option value="3">Чөлөөтэй</option>
                            <option value="4">Гэрээт</option>
                            <option value="5">Ажлаас гарсан</option>
                          </select>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <button
                        className="btn"
                        type="submit"
                        onClick={this.onSearch}
                      >
                        Хайх
                      </button>
                    </Col>
                  </div>
                  {/* </form> */}
                </Row>

                <Row>
                  <div
                    style={{
                      height: "680px",
                      width: "100%",
                      margin: "10px 10px",
                    }}
                  >
                    <DataGrid
                      getRowId={(row) => row._id}
                      rows={this.state.todos}
                      columns={columns}
                      rowsPerPageOptions={[30, 50, 100]}
                      // pageSize={15}
                      checkboxSelection
                      components={{
                        Toolbar: GridToolbar,
                      }}
                      disableColumnMenu
                      onSelectionModelChange={this.handleRowSelection}
                      onRowDoubleClick={(e) =>
                        this.props.history.push("/application/fullInfo/" + e.id)
                      }
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(Verified);
