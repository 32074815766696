import React, { PureComponent } from "react";
import { Col, CardBody, CardTitle, Card } from "reactstrap";
import { reduxForm } from "redux-form";
import axios from "axios";
import { withTranslation } from "react-i18next";
import "react-notifications/lib/notifications.css";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import { Slider } from "antd";
class createSegment extends PureComponent {
  constructor(props) {
    super(props);
    this.onChangeDepartment = this.onChangeDepartment.bind(this);
    this.onChangeworkingYears = this.onChangeworkingYears.bind(this);
    this.onChangeBornDate = this.onChangeBornDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeNetwork = this.onChangeNetwork.bind(this);
    this.onChangeSex = this.onChangeSex.bind(this);
    this.onChangeUaCode = this.onChangeUaCode.bind(this);
    this.onChangeKeyWord = this.onChangeKeyWord.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      department: "",
      working_years: "",
      born_date: "",
      network: "Юнител",
      sex: "Эр",
      ua_code: "",
      company_name: "5bcd43443fe112b9d6160d3d",
      key_word: "",
    };
  }
  onChangeDepartment(e) {
    this.setState({
      department: e.target.value,
    });
  }

  onChangeworkingYears(e) {
    this.setState({
      working_years: e,
    });
  }
  handleChange(e) {
    this.setState({ company_name: e.target.value });
  }
  onChangeBornDate(e) {
    this.setState({ born_date: e.target.value });
  }
  onChangeNetwork(e) {
    this.setState({ network: e.target.value });
  }
  onChangeSex(e) {
    this.setState({ sex: e.target.value });
  }
  onChangeUaCode(e) {
    this.setState({ ua_code: e.target.value });
  }
  onChangeKeyWord(e) {
    this.setState({ key_word: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const segmentData = {
      client_id: "9n8823WHwxvf1I",
      merchant_id: this.state.company_name,
      department: this.state.department,
      working_years: this.state.working_years,
      born_date: this.state.born_date,
      network: this.state.network,
      sex: this.state.sex,
      ua_code: this.state.ua_code,
      key_word: this.state.key_word,
    };

    axios
      .post("https://api.employeeloyalty.upoint.mn/segment/add", segmentData)
      .then((res) => {
        //   this.setState({
        //     company_name: "",
        //     department: "",
        //     working_years: "",
        //     born_date: "",
        //     network: "",
        //     sex: "",
        //     ua_code: "",
        //     key_word: "",
        //   });
        this.props.history.push("/pages/segment");
        console.log(res.data);
        NotificationManager.success(res.data.message, "", 5000);
      });
  }

  render() {
    return (
      <Col md={12} lg={12}>
        <NotificationContainer style={{ position: "absolute" }} />
        <Card className="card-zasah">
          <CardBody>
            <CardTitle className="card__title">Шинэ сегмент үүсгэх</CardTitle>
            <hr className="line" />
            <div style={{ marginTop: 20 }}>
              <form className="form form--horizontal" onSubmit={this.onSubmit}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Байгууллага</span>
                  <div className="form__form-group-field">
                    <select
                      value={this.state.company_name}
                      onChange={this.handleChange}
                    >
                      <option value="5bcd43443fe112b9d6160d3d">Юнител</option>
                      <option value="5bcd43443fe112b9d6160d3a">МТ</option>
                      <option value="5bcd43443fe112b9d6160d3b">Монос</option>
                      <option value="5bcd43443fe112b9d6160d3c">
                        Тү Лэ Жүр
                      </option>
                      <option value="5bcd43443fe112b9d6160d3f">Юнивишн</option>
                      <option value="5bcd43443fe112b9d6160d30">
                        Гэр интернэт
                      </option>
                      <option value="5bcd43443fe112b9d6160d31">Ддэш</option>
                      <option value="5bcd43443fe112b9d6160d32">
                        Кафе Бэнэ
                      </option>
                    </select>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Газар хэлтэс</span>
                  <div className="form__form-group-field">
                    <input
                      name="department"
                      component="input"
                      type="text"
                      placeholder="Газар хэлтэс"
                      value={this.state.department}
                      onChange={this.onChangeDepartment}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Ажилд орсон огноо
                  </span>
                  <div className="form__form-group-field">
                    {/* <input
                      name="working_years"
                      component="input"
                      type="text"
                      placeholder="Ажилд орсон огноо"
                      value={this.state.working_years}
                      onChange={this.onChangeworkingYears}
                    /> */}
                    <div style={{ width: "700px" }}>
                      <Slider
                        defaultValue={3}
                        max={20}
                        // value={this.state.working_years}
                        onAfterChange={this.onChangeworkingYears}
                      />
                      <Slider
                        range
                        step={1}
                        defaultValue={[0, 20]}
                        max={20}
                        // onChange={onChange}
                        // onAfterChange={onAfterChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Төрсөн өдөр</span>
                  <div className="form__form-group-field">
                    <input
                      name="born_date"
                      component="input"
                      type="date"
                      placeholder="Төрсөн өдөр"
                      value={this.state.born_date}
                      onChange={this.onChangeBornDate}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Оператор</span>
                  <div className="form__form-group-field">
                    <select
                      value={this.state.network}
                      onChange={this.onChangeNetwork}
                    >
                      <option>Юнител</option>
                      <option>Мобиком</option>
                      <option>Скайтел</option>
                      <option>Жи-Мобайл</option>
                    </select>
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">Хүйс</span>
                  <div className="form__form-group-field">
                    <select value={this.state.sex} onChange={this.onChangeSex}>
                      <option>Эр</option>
                      <option>Эм</option>
                    </select>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Нөхцөлийн код</span>
                  <div className="form__form-group-field">
                    <input
                      name="ua_code"
                      component="input"
                      type="text"
                      placeholder="Нөхцөлийн код"
                      value={this.state.ua_code}
                      onChange={this.onChangeUaCode}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Түлхүүр үг</span>
                  <div className="form__form-group-field">
                    <input
                      name="key_word"
                      component="input"
                      type="text"
                      placeholder="Түлхүүр үг"
                      value={this.state.key_word}
                      onChange={this.onChangeKeyWord}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <input type="submit" value="Үүсгэх" className="uusgeh" />
                </div>
              </form>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default reduxForm({
  form: "horizontal_form", // a unique identifier for this form
})(withTranslation("common")(createSegment));
