import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { Collapse, Navbar, Nav, NavItem, NavLink, Button } from "reactstrap";
import {authService} from '../../../services/auth.service'
class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };
  logOut() {
    localStorage.clear();
  }
  render() {
    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <Link className="topbar__logo" to="/pages/burtgelerh" />
          </div>
          <div className="navbar">
            <Navbar light expand="md" variant="light">
              <Nav navbar>
                <NavItem>
                  <NavLink>
                    <Link variant="pills" defaultActiveKey="/pages/burtgelerh" to={"/pages/burtgelerh"} style={{ color: "white" }}>
                      БҮРТГЭЛИЙН ЭРХ
                    </Link>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink>
                    <Link
                      to={"/pages/medeelel"}
                      style={{ color: "white", marginLeft: "50px" }}
                    >
                      МЭДЭЭ, МЭДЭЭЛЭЛ
                    </Link>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink>
                    <Link
                      to={"/pages/department"}
                      style={{ color: "white", marginLeft: "50px" }}
                    >
                      КОМПАНИ
                    </Link>
                  </NavLink>
                </NavItem>
                <div className="topbar__right_admin">
                  <NavItem>
                    <NavLink href="/change_password" style={{ color: "white" }}>
                      НУУЦ ҮГ СОЛИХ
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={authService.logout}
                      href="/"
                      style={{
                        backgroundColor: "#40B0EF",
                        color: "white",
                        border: "none",
                        borderRadius: "6px",
                        marginLeft: "50px",
                        width: "70px",
                      }}
                    >
                      ГАРАХ
                    </NavLink>
                  </NavItem>
                </div>
              </Nav>
            </Navbar>
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;
