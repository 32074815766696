import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { Badge, Button, ButtonToolbar, Modal } from "reactstrap";
class table extends Component {
  constructor(props) {
    super(props);
    this.deleteStudent = this.deleteStudent.bind(this);
    this.state = {
      modal: false,
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState((prevState) => ({ modal: !prevState.modal }));
    console.log(this.props, "props");
  }
  deleteStudent() {
    axios
      .delete(
        "https://api.employeeloyalty.upoint.mn/admin/delete/" +
          this.props.todo._id
      )
      .then((res) => {
        console.log(res.data);
        NotificationManager.success(res.data.message, "", 5000);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { modal } = this.state;
    return (
      <tr>
        <td>{this.props.deps.department}</td>
        <td>{this.props.deps.merchant_name}</td>
        <td style={{ width: "100px" }}>
          <Badge color="warning">
            <Link
              to={"/pages/adminBurtgel/edit/" + this.props.deps._id}
              style={{ color: "white" }}
            >
              Засах
            </Link>
          </Badge>
        </td>
        <td style={{ width: "100px" }}>
          <Badge color="danger" onClick={this.toggle}>
            Устгах
          </Badge>
          <Modal isOpen={modal} toggle={this.toggle}>
            <div className="modal__header"></div>
            <div className="modal__body">
              {" "}
              Ажилтан {this.props.deps.username} -г устгах уу?
            </div>
            <ButtonToolbar className="modal__footer">
              <Button className="modal_ok" onClick={this.deleteStudent}>
                Тийм
              </Button>{" "}
              <Button className="modal_cancel" onClick={this.toggle}>
                Үгүй
              </Button>{" "}
            </ButtonToolbar>
          </Modal>
        </td>
      </tr>
    );
  }
}
export default withRouter(table);
