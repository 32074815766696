import React, { PureComponent } from "react";
import { Col, CardBody, CardTitle, Card } from "reactstrap";
import { reduxForm } from "redux-form";
import axios from "axios";
import { withTranslation } from "react-i18next";
import moment from "moment";
import "react-notifications/lib/notifications.css";

import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
class Burtgeh extends PureComponent {
  constructor(props) {
    super(props);

    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSignType = this.onSignType.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      username: "",
      email: "",
      company_name: "5bcd43443fe112b9d6160d3d",
      sign_type: "hr",
    };
  }
  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }
  handleChange(e) {
    this.setState({ company_name: e.target.value });
  }
  onSignType(e) {
    this.setState({ sign_type: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const registrationData = {
      client_id: "9n8823WHwxvf1I",

      merchant_id: this.state.company_name,
      username: this.state.username,
      email: this.state.email,
      role: this.state.sign_type,
    };

    if (this.state.username.length > 0 && this.state.email.length > 0) {
      axios
        .post(
          "https://api.employeeloyalty.upoint.mn/admin/add",
          registrationData
        )
        .then((res) => {
          this.setState({
            username: "",
            email: "",
            company_name: "",
            sign_type: "",
          });
          console.log(res.data, "message");

          if (!res.data.error) {
            this.props.history.push("/pages/burtgelerh");
            console.log(res.data.message, "message");
            NotificationManager.success(res.data.message, "", 5000);
          } else {
            // NotificationManager.error(res.data.error_description, "", 5000);
            console.log(res.message, "message");
          }
        })
        .catch(function (error) {
          NotificationManager.error(error.response.data.message, "", 5000);
        });
    } else {
      NotificationManager.error(
        "Нэвтрэх нэр эсвэл имэйл хоосон утгатай байна!",
        "",
        5000
      );
    }
  }

  // <button className="btn" onClick={this.toggle}>
  //   + Бүртгэлийн эрх үүсгэх
  // </button>

  render() {
    return (
      <Col md={12} lg={12}>
        <NotificationContainer style={{ position: "absolute" }} />
        <Card className="card-zasah">
          <CardBody>
            <CardTitle className="card__title">Бүртгэлийн эрх үүсгэх</CardTitle>
            <hr className="line" />
            <div style={{ marginTop: 20 }}>
              <form className="form form--horizontal" onSubmit={this.onSubmit}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Байгууллагын нэр
                  </span>
                  <div className="form__form-group-field">
                    <select
                      value={this.state.company_name}
                      onChange={this.handleChange}
                    >
                      <option value="5bcd43443fe112b9d6160d3d">Юнител</option>
                      <option value="5bd95078b92b980bb3f1fb91">
                        Юнайтэд Аллианс
                      </option>
                      <option value="5cd404ce5f627d42a5786602">МТ</option>
                      <option value="5d19afa55f627d26cfca48d7">Монос</option>
                      <option value="5c01f71b6dc84937dd321d1b">
                        Тү Лэ Жүр
                      </option>
                      <option value="5c10bc165f627d320c0969c6">Юнивишн</option>
                      <option value="607412575f627d089681fb74">
                        Гэр интернэт
                      </option>
                      <option value="5d142d215f627d62c382f155">Ддэш</option>
                      <option value="5c009abf5f627d2b147a6ad1">
                        Кафе Бэнэ
                      </option>
                      <option value="5ebb942e5f627d4e1e87d4ca">
                        Делүкс кар вош
                      </option>
                      <option value="5faa59d15f627d4e1aeab8f2">
                        Альфабүүк
                      </option>
                      <option value="6007b6bc5f627d4f6441a4f8">Юна</option>
                      <option value="615ac29b5f627d6f04abec50">
                        ЭМ СИ ЭС Холдинг
                      </option>
                      <option value="616528605f627d2e9a2c53d0">
                        М-Си-Эс Пауэр
                      </option>
                      <option value="6165282e5f627d42123019a2">
                        М-Си-Эс Инвестмент
                      </option>
                      <option value="5c1221d85f627d70a4c82ea9">
                        Test Nomin
                      </option>
                      <option value="5b0b78b53e6ae53e2a6a8592">Номин</option>
                      <option value="5c07d9e05f627d06ddb2fdaf">
                        Тэсо корпораци
                      </option>
                      <option value="5c49368b5f627d01ec80a4a2">
                        Тэсо Милко
                      </option>
                      <option value="5c4937095f627d01ec80a4a3">
                        Тэсо Айсмарк
                      </option>
                      <option value="5e71a5795f627d3c51a5b994">BTF</option>
                      <option value="5efaab8f5f627d2fc69ff069">
                        Анунгоо ХХК
                      </option>
                      <option value="5f8423fb5f627d312451d877">
                        Маргет Гейт ХХК
                      </option>
                      <option value="5eaa9e255f627d4f020805f4">
                        М-Си-Эс Кока-Кола ХХК
                      </option>
                      <option value="625cd5885f627d7f91895543">
                        Вэлл Бий ХХК
                      </option>
                      <option value="625cd5985f627d61d10c5a2c">
                        Тотал Дистрибюшин ХХК
                      </option>
                      <option value="625cd5af5f627d1be4cf975c">
                        М-Си-Эс Проперти ХХК
                      </option>
                      <option value="625cd5d05f627d6e087e7f82">
                        Бьюлдинг Мэйнтэнэнс Сервисэс ХХК
                      </option>
                      <option value="625cd5e55f627d635a38d5e8">
                        М-Си-Эс Эстэйтс ХХК
                      </option>
                      <option value="625cd5fb5f627d562e52aadc">
                        М-Си-Эс Интернэйшнл ХХК
                      </option>
                      <option value="625cd6345f627d138f8cc89d">
                        Эрчим Сүлжээ ХХК
                      </option>
                      <option value="625cd6435f627d635a38d621">
                        Интернэйшнл Медикал Cентер ХХК
                      </option>
                      <option value="625cd6585f627d73232ffc54">
                        Майнд Плас ХХК
                      </option>
                      <option value="614165b55f627d6807b3ec71">
                        Топ Моторс ХХК
                      </option>
                      <option value="6007bf935f627d4f6441aa72">
                        Скай Резорт ХХК
                      </option>
                      <option value="625cd7045f627d5a99bc7f36">
                        М-Армор ХХК
                      </option>
                      <option value="625cd7175f627d2be7b37bf8">
                        Юнисервис солюшн ХХК
                      </option>
                      <option value="625cd7475f627d5fa4d10092">
                        Скай Инфра Групп ХХК
                      </option>
                      <option value="625cd75a5f627d5201abf139">М банк</option>
                      <option value="625cd76b5f627d3a0c86077f">
                        Метагро ХХК
                      </option>
                    </select>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Нэвтрэх нэр</span>
                  <div className="form__form-group-field">
                    <input
                      name="username"
                      component="input"
                      type="text"
                      placeholder="Нэвтрэх нэр"
                      value={this.state.username}
                      onChange={this.onChangeUsername}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">И-мэйл хаяг</span>
                  <div className="form__form-group-field">
                    <input
                      name="email"
                      component="input"
                      type="email"
                      placeholder="И-мэйл хаяг"
                      value={this.state.email}
                      onChange={this.onChangeEmail}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Бүртгэлийн эрх сонгох
                  </span>
                  <div className="form__form-group-field">
                    <select
                      value={this.state.sign_type}
                      onChange={this.onSignType}
                    >
                      <option value="hr">Бүртгэх эрх</option>
                      <option value="director">Баталгаажуулах эрх</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <input type="submit" value="Үүсгэх" className="uusgeh" />
                </div>
              </form>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default reduxForm({
  form: "horizontal_form", // a unique identifier for this form
})(withTranslation("common")(Burtgeh));
