import React, { PureComponent } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardTitle,
} from "reactstrap";
import ReactDOM from "react-dom";
import { reduxForm } from "redux-form";
import classnames from "classnames";
import ExampleCard from "./components/ExampleCard";
import Tab2 from "./components/Tab2";
import "../../scss/component/navbar.scss";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import axios from "axios";

class BurtgelErh extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      company_id: "5bcd43443fe112b9d6160d3d",
      hrs: [],
      always: [],
      ceos: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    // this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    axios
      .get(
        "https://api.employeeloyalty.upoint.mn/admin/getHr/" +
          this.state.company_id
      )
      .then((response) => {
        this.setState({ hrs: response.data, always: response.data });
        console.log(this.state.company_id, "company");
      })
      .catch(function (error) {
        console.log(error);
      });
    axios
      .get(
        "https://api.employeeloyalty.upoint.mn/admin/getDirector/" +
          this.state.company_id
      )
      .then((response) => {
        this.setState({ ceos: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  onSearch() {
    console.log(this.state.company_id, "darah uyiin state id");
    axios
      .get(
        "https://api.employeeloyalty.upoint.mn/admin/getHr/" +
          this.state.company_id
      )
      .then((response) => {
        this.setState({ hrs: response.data });
        console.log(this.state.company_id, "company");
      })
      .catch(function (error) {
        console.log(error);
      });
    axios
      .get(
        "https://api.employeeloyalty.upoint.mn/admin/getDirector/" +
          this.state.company_id
      )
      .then((response) => {
        this.setState({ ceos: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleChange(e) {
    this.setState({ company_id: e.target.value });
  }

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
    console.log(this.state.hrs, "hrs");
    console.log(this.state.ceos, "ceos");
    console.log(this.state.company_id, "company_id");
  };

  render() {
    const { activeTab } = this.state;
    let { hrs } = this.state;
    let { ceos } = this.state;
    let { company_id } = this.state;
    return (
      <div className="content">
        <Container style={{ marginLeft: "0px" }}>
          <Row>
            <Col>
              <button className="btn">
                <Link
                  to={"/pages/adminBurtgel/add/"}
                  style={{ color: "white" }}
                >
                  + Бүртгэлийн эрх үүсгэх
                </Link>
              </button>
            </Col>
            <NotificationContainer />
          </Row>
          <Row>
            <Card>
              <CardBody style={{ height: "10%", width: "100%" }}>
                <Col>
                  <CardTitle className="card__title">
                    Бүртгэлийн эрх харах
                  </CardTitle>
                  <hr />
                  {/* <div>
                    <p
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "22px",
                        color: "#057AC4",
                        fontWeight: 400,
                      }}
                    >
                      Бүртгэлийн эрх харах
                    </p>
                    <hr />
                  </div> */}
                </Col>
                {/* <form onSubmit={this.onSearch}> */}
                <Row style={{ marginLeft: "10px", marginTop: "0%" }}>
                  <Col md={2}>
                    <div>
                      <span
                        className="form__form-group-label"
                        style={{ marginTop: "10px" }}
                      >
                        Байгууллага
                      </span>
                      <select
                        value={this.state.company_id}
                        onChange={this.handleChange}
                        className="select-index"
                      >
                        <option value="5bcd43443fe112b9d6160d3d">Юнител</option>
                        <option value="5bd95078b92b980bb3f1fb91">
                          Юнайтэд аллианс
                        </option>
                        <option value="5cd404ce5f627d42a5786602">МТ</option>
                        <option value="5d19afa55f627d26cfca48d7">Монос</option>
                        <option value="5c01f71b6dc84937dd321d1b">
                          Тү Лэ Жүр
                        </option>
                        <option value="5c10bc165f627d320c0969c6">
                          Юнивишн
                        </option>
                        <option value="607412575f627d089681fb74">
                          Гэр интернэт
                        </option>
                        <option value="5d142d215f627d62c382f155">Ддэш</option>
                        <option value="5c009abf5f627d2b147a6ad1">
                          Кафе Бэнэ
                        </option>
                        <option value="5ebb942e5f627d4e1e87d4ca">
                          Делүкс кар вош
                        </option>
                        <option value="5faa59d15f627d4e1aeab8f2">
                          Альфабүүк
                        </option>
                        <option value="6007b6bc5f627d4f6441a4f8">Юна</option>
                        <option value="615ac29b5f627d6f04abec50">
                          ЭМ СИ ЭС Холдинг
                        </option>
                        <option value="616528605f627d2e9a2c53d0">
                          М-Си-Эс Пауэр
                        </option>
                        <option value="6165282e5f627d42123019a2">
                          М-Си-Эс Инвестмент
                        </option>
                        <option value="5c1221d85f627d70a4c82ea9">
                          Test Nomin
                        </option>
                        <option value="5b0b78b53e6ae53e2a6a8592">Номин</option>
                        <option value="5c07d9e05f627d06ddb2fdaf">
                          Тэсо корпораци
                        </option>
                        <option value="5c49368b5f627d01ec80a4a2">
                          Тэсо Милко
                        </option>
                        <option value="5c4937095f627d01ec80a4a3">
                          Тэсо Айсмарк
                        </option>
                        <option value="5e71a5795f627d3c51a5b994">BTF</option>
                        <option value="5efaab8f5f627d2fc69ff069">
                          Анунгоо ХХК
                        </option>
                        <option value="5f8423fb5f627d312451d877">
                          Маргет Гейт ХХК
                        </option>
                        <option value="5eaa9e255f627d4f020805f4">
                          М-Си-Эс Кока-Кола ХХК
                        </option>
                        <option value="625cd5885f627d7f91895543">
                          Вэлл Бий ХХК
                        </option>
                        <option value="625cd5985f627d61d10c5a2c">
                          Тотал Дистрибюшин ХХК
                        </option>
                        <option value="625cd5af5f627d1be4cf975c">
                          М-Си-Эс Проперти ХХК
                        </option>
                        <option value="625cd5d05f627d6e087e7f82">
                          Бьюлдинг Мэйнтэнэнс Сервисэс ХХК
                        </option>
                        <option value="625cd5e55f627d635a38d5e8">
                          М-Си-Эс Эстэйтс ХХК
                        </option>
                        <option value="625cd5fb5f627d562e52aadc">
                          М-Си-Эс Интернэйшнл ХХК
                        </option>
                        <option value="625cd6345f627d138f8cc89d">
                          Эрчим Сүлжээ ХХК
                        </option>
                        <option value="625cd6435f627d635a38d621">
                          Интернэйшнл Медикал Cентер ХХК
                        </option>
                        <option value="625cd6585f627d73232ffc54">
                          Майнд Плас ХХК
                        </option>
                        <option value="614165b55f627d6807b3ec71">
                          Топ Моторс ХХК
                        </option>
                        <option value="6007bf935f627d4f6441aa72">
                          Скай Резорт ХХК
                        </option>
                        <option value="625cd7045f627d5a99bc7f36">
                          М-Армор ХХК
                        </option>
                        <option value="625cd7175f627d2be7b37bf8">
                          Юнисервис солюшн ХХК
                        </option>
                        <option value="625cd7475f627d5fa4d10092">
                          Скай Инфра Групп ХХК
                        </option>
                        <option value="625cd75a5f627d5201abf139">М банк</option>
                        <option value="625cd76b5f627d3a0c86077f">
                          Метагро ХХК
                        </option>
                      </select>
                    </div>
                  </Col>
                  <Col>
                    <button
                      className="btn"
                      type="submit"
                      onClick={this.onSearch}
                      style={{ marginTop: "34px" }}
                    >
                      Шалгах
                    </button>
                  </Col>
                </Row>
                {/* </form> */}
              </CardBody>
            </Card>
          </Row>
          <Row>
            <Col md={5}>
              <div className="tabs tabs--justify">
                <div className=" tabs tabs__wrap">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "20px",
                          color: "#057AC4",
                          fontWeight: 400,
                        }}
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          this.toggle("1");
                        }}
                      >
                        Бүртгэх эрх
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "20px",
                          color: "#057AC4",
                          fontWeight: 4000,
                        }}
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          this.toggle("2");
                        }}
                      >
                        Баталгаажуулах эрх
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <ExampleCard hrs={hrs} company_id={company_id} />
                </TabPane>
                <TabPane tabId="2">
                  <Tab2 ceos={ceos} company_id={company_id} />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default reduxForm({
  form: "vertical_form", // a unique identifier for this form
})(withTranslation("common")(BurtgelErh));
