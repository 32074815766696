import React, { PureComponent } from "react";
import { Col, CardBody, CardTitle, Card, Row } from "reactstrap";
import { reduxForm } from "redux-form";
import axios from "axios";
import { withTranslation } from "react-i18next";
import "react-notifications/lib/notifications.css";
import SegmentSelect from "./segmentSelect";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import EmployeeSelect from "./employeeSelect";

class addEmployee extends PureComponent {
  constructor(props) {
    super(props);

    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeRegistrationNumber =
      this.onChangeRegistrationNumber.bind(this);
    this.onChangePhoneNumber = this.onChangePhoneNumber.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
    this.onChangeDepartment = this.onChangeDepartment.bind(this);
    this.onChangePosition = this.onChangePosition.bind(this);
    this.onChangeWorkStart = this.onChangeWorkStart.bind(this);
    this.onChangeEmpStatus = this.onChangeEmpStatus.bind(this);
    this.onChangeStatusChange = this.onChangeStatusChange.bind(this);
    this.onChangeBuddy = this.onChangeBuddy.bind(this);
    this.onChangeCaregiver = this.onChangeCaregiver.bind(this);
    this.onChangeRewardDate = this.onChangeRewardDate.bind(this);
    this.onChangeGrade = this.onChangeGrade.bind(this);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      last_name: "",
      first_name: "",
      registration_number: "",
      phone_number: "",
      employee_status: "",
      emp_status_change_date: "",
      email: "",
      company_name: "",
      department: "",
      position: "",
      merchant_id: "",
      merchant_name: "",
      work_started_at: "",
      errors: {},
      departments: [],
      employees: [],
      dep: "",
      caregiver: "",
      buddy: "",
      reward_date: "",
      grade: "",
    };
  }
  componentDidMount() {
    axios
      .get("https://api.employeeloyalty.upoint.mn/segment/company")
      .then((response) => {
        this.setState({
          merchant_id: response.data.merchant._id,
          merchant_name: response.data.merchant.name_mn,
          departments: response.data.company,
          department: response.data.company[0].department,
        });
      })
      .catch(function (error) {});

    axios
      .get("https://api.employeeloyalty.upoint.mn/employee/verified_hr", {
        params: {
          name: "",
          phone: "",
          rn: "",
          employee_status: "1",
        },
      })
      .then((response) => {
        this.setState({ employees: response.data });
        console.log(response.data, "employeelist");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  onChangeLastName(e) {
    this.setState({
      last_name: e.target.value,
    });
  }

  onChangeFirstName(e) {
    this.setState({
      first_name: e.target.value,
    });
  }
  onChangeRegistrationNumber(e) {
    this.setState({
      registration_number: e.target.value,
    });
    console.log(this.state.merchant_name, "merchant_name");
  }
  onChangePhoneNumber(e) {
    this.setState({
      phone_number: e.target.value,
    });
  }
  onChangeEmpStatus(e) {
    this.setState({
      employee_status: e.target.value,
    });
  }
  onChangeEmail(e) {
    this.setState({ email: e.target.value });
  }
  onChangeCompanyName(e) {
    this.setState({
      merchant_id: e.target.value,
    });
  }
  onChangeDepartment(e) {
    this.setState({
      department: e.target.value,
    });
  }
  onChangeCaregiver(e) {
    this.setState({
      caregiver: e.target.value,
    });
  }
  onChangeBuddy(e) {
    this.setState({
      buddy: e.target.value,
    });
  }
  onChangePosition(e) {
    this.setState({
      position: e.target.value,
    });
  }
  onChangeWorkStart(e) {
    this.setState({ work_started_at: e.target.value });
  }
  onChangeRewardDate(e) {
    this.setState({ reward_date: e.target.value });
  }
  onChangeStatusChange(e) {
    this.setState({ emp_status_change_date: e.target.value });
  }
  onChangeGrade(e) {
    this.setState({ grade: e.target.value });
  }
  validate() {
    let last_name = this.state.last_name;
    let first_name = this.state.first_name;
    let registration_number = this.state.registration_number;
    let phone_number = this.state.phone_number;
    let email = this.state.email;
    let department = this.state.department;
    let work_started_at = this.state.work_started_at;

    let errors = {};

    let isValid = true;

    if (department === "") {
      isValid = false;

      errors["department"] = "Заавал утга оруулна уу";
    }

    if (!work_started_at) {
      isValid = false;

      errors["work_started_at"] = "Заавал утга оруулна уу";
    }

    if (!registration_number) {
      isValid = false;

      errors["registration_number"] = "Регистрийн дугаараа оруулна уу";
    } else {
      if (typeof registration_number !== "undefined") {
        var pattern = new RegExp(/^[А-ЯӨҮЁ]{2}(\d){8}$/gmu);

        if (!pattern.test(registration_number)) {
          isValid = false;

          errors["registration_number"] = "Регистрийн дугаараа зөв оруулна уу";
        }
      }
    }
    if (!last_name) {
      isValid = false;

      errors["last_name"] = "Овгоо оруулна уу";
    } else {
      if (typeof last_name !== "undefined") {
        var pattern = new RegExp(/^[А-Яа-яӨөҮүЁё-]{1,}$/gmu);

        if (!pattern.test(last_name)) {
          isValid = false;

          errors["last_name"] = "Кириллээр бичнэ үү";
        }
      }
    }
    if (!first_name) {
      isValid = false;

      errors["first_name"] = "Нэрээ оруулна уу";
    } else {
      if (typeof first_name !== "undefined") {
        var pattern = new RegExp(/^[А-Яа-яӨөҮүЁё-]{1,}$/gmu);

        if (!pattern.test(first_name)) {
          isValid = false;

          errors["first_name"] = "Кириллээр бичнэ үү";
        }
      }
    }
    if (!phone_number) {
      isValid = false;

      errors["phone_number"] = "Утасны дугаар оруулна уу";
    } else {
      if (typeof phone_number !== "undefined") {
        var pattern = new RegExp(/^(\+\d{3}(-){0,1}){0,1}([8-9]\d{7})$/gmu);

        if (!pattern.test(phone_number)) {
          isValid = false;

          errors["phone_number"] = "Утасны дугаараа зөв оруулна уу";
        }
      }
    }

    if (typeof email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(email)) {
        isValid = false;

        errors["email"] = "И-мэйл хаягаа зөв оруулна уу";
      }
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }

  onSubmit(e) {
    e.preventDefault();

    let newEmployee;
    if (this.state.merchant_id === "5bd95078b92b980bb3f1fb91") {
      newEmployee = {
        last_name: this.state.last_name,
        first_name: this.state.first_name,
        registration_number: this.state.registration_number,
        phone_number: this.state.phone_number,
        email: this.state.email,
        merchant_id: this.state.merchant_id,
        department: this.state.department,
        position: this.state.position,
        employee_status: this.state.employee_status,
        work_started_at: this.state.work_started_at,
        emp_status_change_date: this.state.emp_status_change_date,
        caregiver: this.state.caregiver,
        buddy: this.state.buddy,
        reward_date: this.state.reward_date,
        grade: this.state.grade,
      };
    } else {
      newEmployee = {
        last_name: this.state.last_name,
        first_name: this.state.first_name,
        registration_number: this.state.registration_number,
        phone_number: this.state.phone_number,
        email: this.state.email,
        merchant_id: this.state.merchant_id,
        department: this.state.department,
        position: this.state.position,
        employee_status: this.state.employee_status,
        work_started_at: this.state.work_started_at,
        emp_status_change_date: this.state.emp_status_change_date,
      };
    }

    if (this.validate()) {
      axios
        .post("https://api.employeeloyalty.upoint.mn/employee/add", newEmployee)
        .then((res) => {
          if (res.data.code !== 0) {
            throw new Error(res.data.message);
          }
          this.props.history.push("/partner/list");
          NotificationManager.success(res.data.message, "", 5000);
        })
        .catch(function (error) {
          console.log(error);
          NotificationManager.error(error.message, "", 5000);
        });
    }
  }
  todoList() {
    return this.state.departments.map(function (currentTodo, i) {
      return <SegmentSelect departments={currentTodo} key={i} />;
    });
  }

  employeeList() {
    return this.state.employees.map(function (employee, i) {
      return <EmployeeSelect employee={employee} key={i} />;
    });
  }

  render() {
    return (
      <Col md={12} lg={12}>
        <NotificationContainer style={{ position: "absolute" }} />
        <Card className="card-zasah">
          <CardBody>
            <CardTitle className="card__title">Гараар бүртгэх</CardTitle>
            <hr className="line" />
            <div style={{ marginTop: 20 }}>
              <form className="form form--horizontal" onSubmit={this.onSubmit}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Овог</span>
                  <div className="form__form-group-field">
                    <input
                      name="last_name"
                      component="input"
                      type="text"
                      placeholder="Овог"
                      value={this.state.last_name}
                      onChange={this.onChangeLastName}
                    />
                  </div>
                </div>
                <div className="text-danger" style={{ marginLeft: "220px" }}>
                  {this.state.errors.last_name}
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">Нэр</span>
                  <div className="form__form-group-field">
                    <input
                      name="first_name"
                      component="input"
                      type="text"
                      placeholder="Нэр"
                      value={this.state.first_name}
                      onChange={this.onChangeFirstName}
                    />
                  </div>
                </div>
                <div className="text-danger" style={{ marginLeft: "220px" }}>
                  {this.state.errors.first_name}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Регистрийн дугаар
                  </span>
                  <div className="form__form-group-field">
                    <input
                      name="registration_number"
                      component="input"
                      type="text"
                      placeholder="Регистрийн дугаар"
                      value={this.state.registration_number}
                      onChange={this.onChangeRegistrationNumber}
                    />
                  </div>
                </div>
                <div className="text-danger" style={{ marginLeft: "220px" }}>
                  {this.state.errors.registration_number}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Утасны дугаар</span>
                  <div className="form__form-group-field">
                    <input
                      name="phone_number"
                      component="input"
                      type="text"
                      placeholder="Утасны дугаар"
                      value={this.state.phone_number}
                      onChange={this.onChangePhoneNumber}
                    />
                  </div>
                </div>
                <div className="text-danger" style={{ marginLeft: "220px" }}>
                  {this.state.errors.phone_number}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">И-мэйл хаяг</span>
                  <div className="form__form-group-field">
                    <input
                      name="email"
                      type="text"
                      placeholder="И-мэйл хаяг"
                      value={this.state.email}
                      onChange={this.onChangeEmail}
                    />
                  </div>
                </div>
                <div className="text-danger" style={{ marginLeft: "220px" }}>
                  {this.state.errors.email}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Байгууллагын нэр
                  </span>
                  <div className="form__form-group-field">
                    <input
                      component="input"
                      type="text"
                      value={this.state.merchant_name}
                      disabled
                      onChange={this.onChangeCompanyName}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Ажилтны статус</span>
                  <div className="form__form-group-field">
                    <select
                      value={this.state.employee_status}
                      onChange={this.onChangeEmpStatus}
                      placeholder="Ажилтны статус"
                      required
                    >
                      <option value="" selected disabled>
                        Ажилтны статус сонгоно уу
                      </option>
                      <option value="1">Ажиллаж байгаа</option>
                      <option value="2">Хүүхэд асрах чөлөөтэй</option>
                      <option value="3">Чөлөөтэй</option>
                      <option value="4">Гэрээт</option>
                      <option value="5">Ажлаас гарсан</option>
                    </select>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Статус солигдсон огноо
                  </span>
                  <div className="form__form-group-field">
                    <input
                      name="status_change_date"
                      component="input"
                      type="date"
                      placeholder="Ажилд орсон огноо"
                      value={this.state.emp_status_change_date}
                      onChange={this.onChangeStatusChange}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Газар, хэлтэс</span>
                  <div className="form__form-group-field">
                    <select
                      // value={this.state.department}
                      onChange={this.onChangeDepartment}
                      placeholder="Газар, хэлтэс"
                      required
                    >
                      <option value="" selected disabled>
                        Газар хэлтэс сонгоно уу
                      </option>
                      {this.todoList()}
                    </select>
                  </div>
                </div>

                <div className="text-danger" style={{ marginLeft: "220px" }}>
                  {this.state.errors.department}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Албан тушаал</span>
                  <div className="form__form-group-field">
                    <input
                      name="position"
                      component="input"
                      type="text"
                      placeholder="Албан тушаал"
                      value={this.state.position}
                      onChange={this.onChangePosition}
                    />
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Ажилд орсон огноо
                  </span>
                  <div className="form__form-group-field">
                    <input
                      name="work_started_at"
                      component="input"
                      type="date"
                      placeholder="Ажилд орсон огноо"
                      value={this.state.work_started_at}
                      onChange={this.onChangeWorkStart}
                    />
                  </div>
                </div>

                {this.state.merchant_id == "5bd95078b92b980bb3f1fb91" ? (
                  <>
                    {" "}
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Жинхлэгдэх өдөр
                      </span>
                      <div className="form__form-group-field">
                        <input
                          name="reward_date"
                          component="input"
                          type="date"
                          placeholder="Жинхлэх өдөр"
                          value={this.state.reward_date}
                          onChange={this.onChangeRewardDate}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Халамжлагч ажилтан
                      </span>
                      <div className="form__form-group-field">
                        <select
                          value={this.state.caregiver}
                          onChange={this.onChangeCaregiver}
                          placeholder="Халамжлагч"
                          required={false}
                        >
                          <option value="" selected disabled>
                            Халамжлагч сонгоно уу
                          </option>
                          <option value="63c4f160b428ab6d61011111">
                            Хоосон
                          </option>
                          {this.employeeList()}
                        </select>
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Ажилтны найз
                      </span>
                      <div className="form__form-group-field">
                        <select
                          value={this.state.buddy}
                          onChange={this.onChangeBuddy}
                          placeholder="Ажилтны найз"
                          required={false}
                        >
                          <option value="" selected disabled>
                            Ажилтны найз сонгоно уу
                          </option>
                          <option value="63c4f160b428ab6d61011111">
                            Хоосон
                          </option>
                          {this.employeeList()}
                        </select>
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Ажилтны түвшин
                      </span>
                      <div className="form__form-group-field">
                        <select
                          value={this.state.grade}
                          onChange={this.onChangeGrade}
                          placeholder="Ажилтны түвшин"
                          required
                        >
                          <option value="" selected disabled>
                            Ажилтны түвшин сонгоно уу
                          </option>
                          <option value="1">Ажилтан</option>
                          <option value="2">Ахлах ажилтан</option>
                          <option value="3">Менежмент</option>
                        </select>
                      </div>
                    </div>
                    <div
                      className="text-danger"
                      style={{ marginLeft: "220px" }}
                    >
                      {this.state.errors.work_started_at}
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <br />
                <div>
                  <input type="submit" value="Үүсгэх" className="uusgeh" />
                </div>
              </form>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
// export default reduxForm({
//   form: "horizontal_form", // a unique identifier for this form
// })(withTranslation("common")(addEmployee));
export default reduxForm({
  form: "horizontal_form", // a unique identifier for this form
})(withTranslation("common")(addEmployee));
