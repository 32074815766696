import React, { PureComponent } from "react";
import { Col, CardBody, CardTitle, Card, Row } from "reactstrap";
import { reduxForm, Field } from "redux-form";
import axios from "axios";
import { withTranslation } from "react-i18next";
import "react-notifications/lib/notifications.css";

import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import { Upload, message, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Input } from "antd";

class addNews extends PureComponent {
  constructor(props) {
    super(props);

    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangePicture = this.onChangePicture.bind(this);
    this.onChangeShort_desc = this.onChangeShort_desc.bind(this);
    this.onChangeDesc = this.onChangeDesc.bind(this);
    this.onChangeNews = this.onChangeNews.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);

    this.state = {
      title: "",
      picture: "",
      short_desc: "",
      desc: "",
      news: "",
      company_name: "5bcd43443fe112b9d6160d3d",
    };
  }
  componentDidMount() {
    axios
      .get("https://api.employeeloyalty.upoint.mn/segment/company")
      .then((response) => {
        this.setState({
          segments: response.data,
          segment: response.data[0].key_word,
        });
        console.log(response.data, "todos");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  onChangeTitle(e) {
    this.setState({
      title: e.target.value,
    });
    console.log(this.state.picture, "picture");
  }
  handleChange(e) {
    this.setState({ company_name: e.target.value });
  }
  onChangePicture(e) {
    this.setState({
      first_name: e.target.value,
    });
  }
  onChangeShort_desc(e) {
    this.setState({
      short_desc: e.target.value,
    });
  }
  onChangeDesc(e) {
    this.setState({
      desc: e.target.value,
    });
  }
  onChangeNews(e) {
    this.setState({ news: e.target.value });
  }
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      this.setState({
        picture: info.file.response.data.path,
      });
      console.log(info.file.response.data.path);
      //   NotificationManager.success(res.data.message, "", 5000);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }
  onSubmit(e) {
    e.preventDefault();
    console.log(this.state.segment, "segments");

    const newNews = {
      picture: this.state.picture,
      title: this.state.title,
      short_desc: this.state.short_desc,
      desc: this.state.desc,
      news: this.state.news,
    };

    console.log(this.state, "state");
    axios
      .post("https://api.employeeloyalty.upoint.mn/news/add", newNews)
      .then((res) => {
        this.setState({
          picture: "",
          title: "",
          short_desc: "",
          desc: "",
          news: "",
        });
        this.props.history.push("/pages/medeelel");
        console.log(res.data);
        NotificationManager.success(res.data.message, "", 5000);
      });
  }

  render() {
    const props = {
      name: "image",
      action: "http://10.10.10.161:3040/v1/file",
    };
    const { TextArea } = Input;
    return (
      <Col md={12} lg={12}>
        <NotificationContainer style={{ position: "absolute" }} />
        <Card className="card-zasah">
          <CardBody>
            <CardTitle className="card__title">
              Мэдээ, мэдээлэл үүсгэх
            </CardTitle>
            <hr className="line" />
            <div style={{ marginTop: 20 }}>
              <form className="form form--horizontal" onSubmit={this.onSubmit}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Зураг</span>
                  <div className="form__form-group-field">
                    <Upload {...props} onChange={this.onChange}>
                      <Button icon={<UploadOutlined />}>Зураг оруулах</Button>
                    </Upload>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Гарчиг</span>
                  <div className="form__form-group-field">
                    <input
                      name="title"
                      component="input"
                      type="text"
                      placeholder="Гарчиг"
                      value={this.state.title}
                      onChange={this.onChangeTitle}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Байгууллага</span>
                  <div className="form__form-group-field">
                    <select
                      value={this.state.company_name}
                      onChange={this.handleChange}
                    >
                      <option value="5bd95078b92b980bb3f1fb91">
                        Юнайтэд Аллианс
                      </option>
                      <option value="5bcd43443fe112b9d6160d3d">Юнител</option>
                      <option value="5cd404ce5f627d42a5786602">МТ</option>
                      <option value="5d19afa55f627d26cfca48d7">Монос</option>
                      <option value="5c01f71b6dc84937dd321d1b">
                        Тү Лэ Жүр
                      </option>
                      <option value="5c10bc165f627d320c0969c6">Юнивишн</option>
                      <option value="607412575f627d089681fb74">
                        Гэр интернэт
                      </option>
                      <option value="5d142d215f627d62c382f155">Ддэш</option>
                      <option value="5c009abf5f627d2b147a6ad1">
                        Кафе Бэнэ
                      </option>
                    </select>
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Танилцуулга текст
                  </span>
                  <div className="form__form-group-field">
                    <input
                      name="intro"
                      component="input"
                      type="text"
                      placeholder="Танилцуулга текст"
                      value={this.state.short_desc}
                      onChange={this.onChangeShort_desc}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Дэлгэрэнгүй товч
                  </span>
                  <div className="form__form-group-field">
                    <TextArea
                      rows={4}
                      value={this.state.desc}
                      onChange={this.onChangeDesc}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Мэдээ</span>
                  <div className="form__form-group-field">
                    <TextArea
                      rows={12}
                      value={this.state.news}
                      onChange={this.onChangeNews}
                    />
                  </div>
                </div>
                <div>
                  <input type="submit" value="Үүсгэх" className="uusgeh" />
                </div>
              </form>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default reduxForm({
  form: "horizontal_form", // a unique identifier for this form
})(withTranslation("common")(addNews));
